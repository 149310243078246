import { store } from '~/pages/heineken_template/_private/store';
import { css } from '@emotion/react';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { Daddy960_Footer } from '../daddy960_opkevin/component/daddy960_Footer';
import { fr_serverTime } from '../heineken_template/_fr/fr_serverTime';
import { fr_me } from '../heineken_template/_fr/fr_me';
import { AgentProduct } from '~/configs/AgentProduct';
import dayAPI from '~/utils/dayAPI';
import { Daddy960_TopBar } from '../daddy960_opkevin/component/daddy960_TopBar';
import { Daddy960LoginView } from '../daddy960_opkevin/component/Daddy960LoginView';
import { daddy960_tc1688_initStrategies } from './daddy960_tc1688_initStrategies';
import { Tc1688_SidePane1 } from './tc1688_SidePane1';
import { Tc1688_SidePane2 } from './tc1688_SidePnae2';
import { daddy960_tc1688_initStyling } from './daddy960_tc1688_initStyling';
import { useSignalrStart2_0 } from '~/modules/SDK/Signalr/useSignalrStart2_0';
export const daddy960_tc1688_init = (templateProps) => {
    daddy960_tc1688_initStyling(templateProps);
    daddy960_tc1688_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode('dark');
    // templateProps.permissions.pageview =
    // meCheckHandlerCreateByProductNameWithExpiredAt('web_tc1688')
    templateProps.hooks.add(fr_serverTime.useInstall);
    templateProps.hooks.add(fr_me.useInstall);
    templateProps.permissions.pageview =
        fr_me.toTemplate.permissions.hasOneOfPermissionOrHasLoginBetweenServerDateRange({
            agentProduct: [AgentProduct['daddy960@web_tc1688'], AgentProduct['daddy960@web_tc1688_pro']],
            dateRange: [dayAPI('2023/09/25 06:00'), dayAPI('2023/10/16 06:00')],
        });
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'XAUUSD',
        interval: '60',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: false,
        disabledPaneMenu: true,
        disabledTimeframesToolbar: true,
        disabledHeaderChartType: false,
        disabledHeaderSaveload: false,
        disabledHeaderCompare: false,
        overrides: {
            ...store.charting.darkOverrides,
            'paneProperties.legendProperties.showLegend': false,
        },
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px calc(100vh - 48px) 1fr;
    grid-template-columns: 336px 336px 1fr;
    place-content: flex-start;

    grid-template-areas:
      'Row1 Row1 Row1'
      'Drawer1 Drawer2 Chart'
      'Row2 Row2 Row2';

    ${createIPadCss(css `
      grid-template-rows: 48px calc(100% - 56px) 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px calc(100% - 56px) 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1 Row1'
        'Chart Chart Chart'
        'Row2 Row2 Row2';
    `)}
  `;
    templateProps.layout.Row1 = (<Daddy960_TopBar leftBurger={true} product={'tc1688'} clientId={[
            { label: '自用樣板', value: 'daddy960' },
            { label: '導航樣板', value: 'daddy960_tony' },
        ]}/>);
    templateProps.layout.Row2 = Daddy960_Footer;
    templateProps.layout.Drawer1 = Tc1688_SidePane1;
    templateProps.layout.Drawer2 = Tc1688_SidePane2;
    templateProps.layout.login = (<Daddy960LoginView product='tc1688' faviconUrl='tc1688/favicon.png' lineLink='https://page.line.me/?accountId=730hvfcs'/>);
    templateProps.hooks.add(useSignalrStart2_0);
};
